<template>
    <div class="app-subscriber-error">
        <div class="app-subscriber-error__message">
            <font-awesome-icon class="fa-solid app-subscriber-error__message--ban" :icon="['far', 'ban']" />
            <div>{{ message }}</div>
        </div>
        <div class="app-subscriber-error__close" @click="closeClicked">
            x
        </div>
    </div>
</template>
<script>
export default {
    name: 'AppSubscriberError',
    props: {
        message: {
            type: String,
            required: true
        }
    },
    methods: {
        closeClicked() {
            this.$emit('close');
        }
    }
};
</script>
<style lang="scss">
.app-subscriber-error {
    width: 25.375rem;

    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $daintree;

    padding: 1rem 0.5rem 1rem 1rem;

    background: $lavender-blush;

    border: 1px solid $coral-red;
    box-sizing: border-box;

    border-radius: 0.3rem;

    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__message {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        &--ban {
            color: $coral-red;
            margin-right: 0.75rem;
        }
    }

    &__close {
        cursor: pointer;
        margin-left: 1rem;
        margin-right: 0.25rem;
    }
}
</style>
