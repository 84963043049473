<template>
    <div id="app-subscriber-base">
        <img class="app-subscriber-base__left-image" src="@/assets/svg/subscriber-left.svg" />
        <img class="app-subscriber-base__right-image" src="@/assets/svg/subscriber-right.svg" />
        <app-subscriber-error v-if="isShowError" :message="errorMessage" @close="hideErrorMessage" />
        <div class="app-subscriber-base__main-view">
            <router-view />
        </div>
    </div>
</template>

<script>
import logger from '@/lib/logger';
import logrocket from '@/lib/logrocket';
import AppSubscriberError from '@/components/AppSubscriber/common/AppSubscriberError';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'AppSubscriberBase',
    components: {
        AppSubscriberError
    },
    computed: {
        ...mapState('login', ['showErrorLogin', 'loginErrorMessage']),
        ...mapState('consent', ['showErrorConsent', 'consentErrorMessage']),
        isShowError() {
            return this.showErrorLogin || this.showErrorConsent;
        },
        errorMessage() {
            if (this.showErrorLogin) {
                return this.loginErrorMessage;
            }

            if (this.showErrorConsent) {
                return this.consentErrorMessage;
            }

            return '';
        }
    },
    created() {
        logrocket.init();
        logger.debug(`${this.$options.name} AppSubscriberBase.vue created.`);
    },
    methods: {
        ...mapActions('login', ['initializeErrorDetailsLogin']),
        ...mapActions('consent', ['initializeErrorDetailsConsent']),
        hideErrorMessage() {
            this.initializeErrorDetailsLogin();
            this.initializeErrorDetailsConsent();
        }
    }
};
</script>

<style lang="scss">
#app-subscriber-base {
    @media (-webkit-min-device-pixel-ratio: 1.5) and (-webkit-max-device-pixel-ratio: 1.5) {
        zoom: 0.65;
    }

    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .app-subscriber-base__left-image {
        height: 100%;
        float: left;
    }

    .app-subscriber-base__right-image {
        height: 100%;
        float: right;
    }

    .app-subscriber-base__main-view {
        width: 27.375rem;
        height: 39.375rem;

        background: $white;

        border: 1px solid $gallery;
        box-sizing: border-box;

        box-shadow: 0 0.2rem 0.9rem rgba($silver, 0.5);
        border-radius: 0.3rem;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
